import { useNavigate } from 'react-router-dom'

import { ImageContent } from '@app/assets/ImageContent'
import { Button } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { useTranslation } from '@app/locales'

import { useStudentInfo } from '../data'

export const AllDoneForToday = () => {
  const { t } = useTranslation(['common', 'dashboard'])
  const navigate = useNavigate()

  const { studentInfo } = useStudentInfo()

  return (
    <div className="relative flex w-full flex-col p-4 pt-8">
      <div className="relative mt-8 flex w-full justify-center">
        <svg
          className="absolute -top-10 w-full min-w-[320px]"
          width="390"
          height="414"
          viewBox="0 0 390 414"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_272_19782)">
            <circle cx="195" cy="207" r="171.5" stroke="url(#paint0_radial_272_19782)" strokeWidth="15" />
          </g>
          <g filter="url(#filter1_f_272_19782)">
            <circle cx="195" cy="207" r="166.5" stroke="#F3C5FF" strokeWidth="5" />
          </g>
          <g filter="url(#filter2_f_272_19782)">
            <circle cx="195" cy="207" r="162.5" stroke="#ECCDFF" strokeWidth="3" />
          </g>
          <defs>
            <filter
              id="filter0_f_272_19782"
              x="-11.2"
              y="0.799999"
              width="412.4"
              height="412.4"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="13.6" result="effect1_foregroundBlur_272_19782" />
            </filter>
            <filter
              id="filter1_f_272_19782"
              x="13.9"
              y="25.9"
              width="362.2"
              height="362.2"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="6.05" result="effect1_foregroundBlur_272_19782" />
            </filter>
            <filter
              id="filter2_f_272_19782"
              x="29"
              y="41"
              width="332"
              height="332"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_272_19782" />
            </filter>
            <radialGradient
              id="paint0_radial_272_19782"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(195 207) rotate(90) scale(164)"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="#E69FFF" />
            </radialGradient>
          </defs>
        </svg>

        <img
          src={ImageContent.tasks.allDoneForToday}
          className="aspect-square max-w-[328px] self-center object-cover"
        />
      </div>

      <div className="mt-6 flex flex-col items-center gap-4 px-4">
        <h1 className="w-full text-center text-4xl font-bold text-[#F1F1F1]">{t('allDone.title')}</h1>
        <p className="w-full text-center font-light text-[#F1F1F1]">
          {t('allDone.description', { name: studentInfo?.student.userProfile.firstName })}
        </p>

        {Constants.ENABLE_LEARNING_PATH && (
          <Button
            className="w-full max-w-[480px]"
            onClick={() => {
              navigate(Pages.DASHBOARD_LEARNING_PATH)
            }}
          >
            {t('allDone.training')}
          </Button>
        )}
      </div>
    </div>
  )
}
