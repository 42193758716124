import jwt_decode, { JwtPayload } from 'jwt-decode'

import Constants from '@app/constants'
import { logError } from '@app/utils/logsUtils'

import { localTokenStore } from '../store/tokenStore'

export const readAccessToken = (): string | null => {
  return localTokenStore.getAccessToken()
}

export const saveAccessToken = (accessToken: string): void => {
  return localTokenStore.setAccessToken(accessToken)
}

export const readRefreshToken = (): string | null => {
  try {
    return localTokenStore.getRefreshToken()
  } catch (error) {
    logError(error, 'localTokenActions', 'refreshToken')
    return null
  }
}

export const saveRefreshToken = (refreshToken: string): void => {
  try {
    localTokenStore.setRefreshToken(refreshToken)
  } catch (error) {
    logError(error, 'localTokenActions', 'saveRefreshToken')
  }
}

export const isTokenStillValid = (
  tokenString: string | null | undefined,
  tokenThreshold: number = Constants.AUTH_TOKEN_EXPIRATION_THRESHOLD
): tokenString is string => {
  if (tokenString) {
    const token = jwt_decode<JwtPayload>(tokenString)
    if (token?.exp) {
      const date = new Date(token.exp * 1000)
      return date.getTime() >= Date.now() - tokenThreshold
    }
  }
  return false
}

// It returns the number of milliseconds after which it is time to renew the token
export const getTimeToRefreshToken = (accessTokenString: string | undefined): number => {
  if (accessTokenString) {
    const token = jwt_decode<JwtPayload>(accessTokenString)
    if (token?.exp) {
      const date = new Date(token.exp * 1000)
      return date.getTime() - Constants.AUTH_TOKEN_EXPIRATION_THRESHOLD - Date.now()
    }
  }
  return -1
}
