import { useState } from 'react'
import { useToggle } from 'react-use'
import { ChevronDown } from '@untitled-ui/icons-react'

import { Checkbox } from '@foxino/components-web'

import { Button, Text } from '@app/components'
import Constants from '@app/constants'
import { flashcardCombinations } from '@app/modules/lesson/model/constants'

export const FlashcardsSelection = () => {
  const [selectedTypes, setSelectedTypes] = useState<keyof typeof flashcardCombinations | undefined>(undefined)
  const [isVisible, toggleVisible] = useToggle(false)

  const handleOnSwitch = (type: keyof typeof flashcardCombinations) => {
    setSelectedTypes(type)

    const selectedType = flashcardCombinations[type]
    Constants.SUPPORTED_FLASHCARD_FULLSCREEN_TYPES = selectedType.fullscreenType ? [selectedType.fullscreenType] : []
    Constants.SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES = selectedType.assignmentType ? [selectedType.assignmentType] : []
    Constants.SUPPORTED_FLASHCARD_RESPONSE_TYPES = selectedType.responseType ? [selectedType.responseType] : []
  }

  return (
    <div className="max-h-[20rem] w-full max-w-[400px] overflow-y-auto rounded-lg bg-white">
      <div
        className="flex cursor-pointer items-center justify-between rounded-lg bg-btn-primary-outline-bg-gradient px-4 py-1"
        onClick={toggleVisible}
      >
        <h2>Flashcard type selection</h2>
        <ChevronDown />
      </div>
      {isVisible && (
        <div className="flex flex-col rounded-lg px-4">
          <Button
            onClick={() => {
              Constants.clearAllAsyncValues(false)
              toggleVisible()
            }}
          >
            Clear settings
          </Button>
          {Object.keys(flashcardCombinations).map((flashcardType) => {
            const type = flashcardType as keyof typeof flashcardCombinations
            const { isDisabled, assignmentType, fullscreenType, responseType } = flashcardCombinations[type]
            const value =
              selectedTypes === type ||
              (fullscreenType && Constants.SUPPORTED_FLASHCARD_FULLSCREEN_TYPES.includes(fullscreenType)) ||
              (assignmentType &&
                Constants.SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES.includes(assignmentType) &&
                responseType &&
                Constants.SUPPORTED_FLASHCARD_RESPONSE_TYPES.includes(responseType))

            return (
              <div key={flashcardType} className="flex items-center gap-2 py-1">
                <Checkbox size="big" disabled={isDisabled} checked={value} onChange={() => handleOnSwitch(type)} />
                <Text>{flashcardType}</Text>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
