import { useEffect, useState } from 'react'
import { App } from '@capacitor/app'
import { LiveUpdate } from '@capawesome/capacitor-live-update'
import * as Sentry from '@sentry/react'

import Constants from '@app/constants'
import { logError } from '@app/utils/logsUtils'
import { isNative } from '@app/utils/platformUtils'

import { version } from '../../../../package.json'
import { localSettingsStore } from '../store/localSettingsStore'

export const useCheckNewJSVersion = (isAuthenticated?: boolean) => {
  const [newVersionAvailable, setNewVersionAvailable] = useState('')

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const checkOnlineVersion = async () => {
      try {
        // Fetch the remote version from the address
        const versionUrl = `${Constants.APP_URL}/info/version`
        console.info('[JS - version] before remoteVersionResponse fetch', versionUrl)
        const remoteVersionResponse = await fetch(versionUrl)
        const remoteVersionObject = await remoteVersionResponse.json()
        const remoteVersion = remoteVersionObject?.build

        localSettingsStore.setBuildBuildNumber(remoteVersion)
        Sentry.setContext('js-version', { remoteVersion, version })

        console.info('[checkOnlineVersion] remoteVersion', remoteVersion)
        return remoteVersion
      } catch (error) {
        logError(error, 'RoleProvider', 'checkVersion', 'Error checking version', false)
        return ''
      }
    }

    const checkVersionNative = async () => {
      try {
        const { bundleId: localVersion } = await LiveUpdate.getCurrentBundle()
        console.info('[JS - version] localVersion', localVersion)

        const remoteVersion = await checkOnlineVersion()

        console.info('[JS - version] Versions: remote/local', remoteVersion, localVersion)

        Sentry.setContext('app-version', { localVersion })

        // Compare the versions
        if (localVersion !== remoteVersion && remoteVersion.length > 0 && remoteVersion.length < 10) {
          console.info('[JS - version] New version available')
          setNewVersionAvailable(remoteVersion)
        } else {
          console.info('[JS - version] No new version available')

          LiveUpdate.ready()
        }
      } catch (error) {
        logError(error, 'RoleProvider', 'checkVersion', 'Error checking version', false)
      }
    }

    console.info('[useCheckNewJSVersion] isNative', isNative())
    if (isNative() && !import.meta.env.DEV) {
      console.info('[useCheckNewJSVersion] before checkVersion')
      checkVersionNative()

      App.addListener('appStateChange', async (state) => {
        if (state.isActive) {
          console.info('[JS - version] App is active, readying LiveUpdate')
          checkVersionNative()
        }
      })
    } else {
      checkOnlineVersion()
    }

    return () => {
      App.removeAllListeners()
    }
  }, [isAuthenticated])

  return { newVersionAvailable, setNewVersionAvailable }
}
