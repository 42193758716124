import { PropsWithChildren, Suspense, useEffect } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { BookOpen01, Calendar, Home02, Trophy01 } from '@untitled-ui/icons-react'

import { profilePicturesContent, useTimeout } from '@foxino/components-common'

import { Label } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { useTranslation } from '@app/locales'
import i18n from '@app/locales/i18n'
import { appLanguageToCode, languageCodeToShort, SupportedAppLanguage } from '@app/locales/types'
import { useProfileSettings } from '@app/modules/settings/data'
import { isCurrentPathActive } from '@app/utils/commonUtils'
import { hapticsImpactLight } from '@app/utils/hapticsUtils'
import { isNativeIOS } from '@app/utils/platformUtils'

import { localSettingsStore } from '../../store/localSettingsStore'
import { useDashboardMenuHeight } from './useDashboardMenuHeight'

const WAIT_FOR_STUDENT_INFO = 10_000

export const DashboardLayout = () => {
  const { t } = useTranslation('dashboard')
  const { pathname } = useLocation()
  const [callStudentInfoTimeout, isWaitingStudentInfo, clearStudentInfoTimeout] = useTimeout(WAIT_FOR_STUDENT_INFO)

  const appLanguage = localSettingsStore.useStore((state) => state.appLanguage)
  const bottomBarHeight = useDashboardMenuHeight()

  const { studentProfile, actions } = useProfileSettings()
  const { backgroundColor, image } = profilePicturesContent[studentProfile?.userProfile.pictureUrl || 'image1']

  useEffect(() => {
    const changeLanguage = async () => {
      if (!studentProfile?.applicationSettings.appLanguage) {
        return
      }

      const profileLanguage = studentProfile?.applicationSettings.appLanguage as unknown as SupportedAppLanguage
      if (profileLanguage !== appLanguage) {
        localSettingsStore.setAppLanguage(profileLanguage)
        const storedAppLang = localSettingsStore.useStore.getState().appLanguage
        const appLang = storedAppLang ? appLanguageToCode[storedAppLang] : 'cs-CZ'
        dayjs.locale(languageCodeToShort[appLang] || 'cs')

        await i18n.changeLanguage(appLanguageToCode[profileLanguage])
      }
    }

    changeLanguage()
  }, [appLanguage, studentProfile?.applicationSettings.appLanguage])

  useEffect(() => {
    // Refetch student info if the user navigates to a new page in dahsboard and the student info is not yet loaded
    // and timer is passed (WAIT_FOR_STUDENT_INFO)
    if (studentProfile?.userProfile && isWaitingStudentInfo) {
      actions.refetchStudentInfo()
      callStudentInfoTimeout()
    }
  }, [pathname])

  useEffect(() => {
    return () => {
      clearStudentInfoTimeout()
    }
  }, [])

  return (
    <Suspense>
      <ScrollToTop />

      <div className="flex h-screen w-full max-w-[1312px] flex-col justify-between">
        <Outlet />

        <div
          style={{
            height: isNativeIOS() ? `calc(var(--safe-area-inset-bottom) + ${bottomBarHeight}px)` : bottomBarHeight,
            background: '#7745C9',
            boxShadow:
              '2px 2px 10px 0px rgba(170, 117, 240, 0.20) inset, 0px 1px 1px 0px rgba(215, 188, 251, 0.25) inset, -86px -109px 55px 0px rgba(78, 0, 194, 0.01), -134px -170px 61px 0px rgba(78, 0, 194, 0.00), -48px -61px 47px 0px rgba(78, 0, 194, 0.05), -21px -27px 35px 0px rgba(78, 0, 194, 0.09), -5px -7px 19px 0px rgba(78, 0, 194, 0.10)'
          }}
          className="fixed bottom-0 z-[1002] flex w-full max-w-[1312px] flex-row items-start overflow-hidden rounded-t-2xl"
        >
          {Constants.ENABLE_LEARNING_PATH && (
            <AnimatedWrapper>
              <NavLink
                to={Pages.DASHBOARD_LEARNING_PATH}
                className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_LEARNING_PATH))}
                onClick={() => hapticsImpactLight()}
              >
                <Home02 className="h-6 w-6 text-neutral50" />
                <Label size="m" weight="medium">
                  {t('menu.activities')}
                </Label>
              </NavLink>
            </AnimatedWrapper>
          )}

          <AnimatedWrapper>
            <NavLink
              to={Pages.DASHBOARD_TASKS}
              className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_TASKS))}
              onClick={() => hapticsImpactLight()}
            >
              <Calendar className="h-6 w-6 text-neutral50" />
              <Label size="m" weight="medium">
                {t('menu.tasks')}
              </Label>
            </NavLink>
          </AnimatedWrapper>

          <AnimatedWrapper>
            <NavLink
              to={Pages.DASHBOARD_LEADER_BOARD}
              className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_LEADER_BOARD))}
              onClick={() => hapticsImpactLight()}
            >
              <Trophy01 className="h-6 w-6 text-neutral50" />
              <Label size="m" weight="medium">
                {t('menu.leaderBoard')}
              </Label>
            </NavLink>
          </AnimatedWrapper>

          <AnimatedWrapper>
            <NavLink
              to={Pages.DASHBOARD_LIBRARY}
              className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_LIBRARY))}
              onClick={() => hapticsImpactLight()}
            >
              <BookOpen01 className="h-6 w-6 text-neutral50" />
              <Label size="m" weight="medium">
                {t('menu.library')}
              </Label>
            </NavLink>
          </AnimatedWrapper>

          <AnimatedWrapper>
            <NavLink
              to={Pages.DASHBOARD_MY_PROFILE}
              className={getCommonNavLinkClassName(isCurrentPathActive(pathname, Pages.DASHBOARD_MY_PROFILE))}
              onClick={() => hapticsImpactLight()}
            >
              <div style={{ backgroundColor }} className="h-6 w-6 rounded-full">
                <img src={image} className="pointer-events-none h-full w-full" />
              </div>
              <Label size="m" weight="medium">
                {t('menu.profile')}
              </Label>
            </NavLink>
          </AnimatedWrapper>
        </div>
      </div>
    </Suspense>
  )
}

const ScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    // scroll to the top of the page after a route change
    window.scrollTo(0, 0)
  }, [location])

  return null
}

const AnimatedWrapper = ({ children }: PropsWithChildren) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: 'spring', stiffness: 500 }}
      className="flex w-full justify-center"
    >
      {children}
    </motion.div>
  )
}

const getCommonNavLinkClassName = (isActive: boolean) =>
  classNames(
    'flex w-full flex-col items-center gap-1 text-sm pt-4 text-neutral50  font-semibold',
    isActive ? 'opacity-100' : 'opacity-50'
  )
