import { Suspense, useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useTitle } from 'react-use'
import { SafeArea } from 'capacitor-plugin-safe-area'
import classNames from 'classnames'
import { registerSW } from 'virtual:pwa-register'

import { initializeSentry, isSentryInitialized } from '@app/config/analytics/initializeSentry'
import { browserRouter } from '@app/config/router/browserRouter'
import i18n from '@app/locales/i18n'
import { GraphqlProvider } from '@app/modules/common/providers'

import { KeycloakProvider } from './modules/auth/providers/KeycloakProvider'
import { isMobile, isNative } from './utils/platformUtils'
import Constants from './constants'

registerSW({
  immediate: true,
  // TODO: test this if it works
  onNeedRefresh() {
    if (confirm('New version available. Do you want to reload the app?')) {
      window.location.reload()
    }
  },
  onOfflineReady() {
    console.info('The application is ready to work offline.')
  }
})

if (!isSentryInitialized() && import.meta.env.DEV === false) {
  initializeSentry()
}

const App = () => {
  console.info('[App] window.location.origin', window.location.origin)
  console.info('[App] window.location.href', window.location.href)

  // const shouldChangeOrigin = window.location.origin === Constants.ANDROID_NATIVE_LOCALHOST && !config.server?.url
  // console.log('[App] shouldChangeOrigin', shouldChangeOrigin)
  // console.log('[App] isAndroid', isAndroid)

  // // Redirect to localhost web server (Bundled javascript and html) if the app is running on native
  // if (isNative() && shouldChangeOrigin) {
  //   console.info('[App] Redirecting to localhost web server of native app')
  //   window.location.href = Constants.ANDROID_NATIVE_LOCALHOST
  // }

  useTitle(Constants.ENVIRONMENT === 'development' ? '(DEV) Foxino - Student' : 'Foxino - Student')

  useEffect(() => {
    const style = document.createElement('style')

    if (isNative()) {
      // hide all scrollbars on native app
      style.textContent = `
        * {
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* Internet Explorer 10+ */
        }
        *::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera */
        }
      `
    }

    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  useEffect(() => {
    ;(async function () {
      const safeAreaData = await SafeArea.getSafeAreaInsets()

      const { insets } = safeAreaData

      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`)
      }
    })()
  }, [])

  // if (isNative() && shouldChangeOrigin) {
  //   return <LoaderWrapper id="change-origin-loader" loading className="h-screen" />
  // }

  return (
    <I18nextProvider i18n={i18n}>
      <KeycloakProvider>
        <GraphqlProvider>
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={true}
            closeButton={true}
            draggable={true}
            draggablePercent={10}
            position={isMobile() ? 'top-center' : 'top-right'}
            className={classNames(isMobile() && 'px-4 top-safe')}
          />
          {/* Test of suspense to get rid of errors: "Cannot access 'MainLayout' before initialization" */}
          <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={browserRouter} />
          </Suspense>
        </GraphqlProvider>
      </KeycloakProvider>
    </I18nextProvider>
  )
}

export default App
