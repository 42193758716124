import { createWithEqualityFn } from 'zustand/traditional'

import Constants from '@app/constants'
import { InteractionType, LearningAssignmentType, NextFlashCardInput, Skill, Subject } from '@app/data'

type LessonInputsStoreType = {
  nextFlashCardInput: NextFlashCardInput
  timeLimitInSeconds?: number
  assignmentType?: LearningAssignmentType
  skills?: Skill[]
  startTimestamp?: number
  subject?: Subject
  canSpeak: boolean
  canListen: boolean
  defaultName?: string
  previousRouter?: string
}

const initialState: LessonInputsStoreType = {
  nextFlashCardInput: {
    learningExecutionId: '',
    interactionTypes: [InteractionType.LISTENING, InteractionType.SPEAKING],
    supportedFlashCardAssignmentTypes: Constants.SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES,
    supportedFlashCardResponseTypes: Constants.SUPPORTED_FLASHCARD_RESPONSE_TYPES,
    supportedFullScreenFlashCardTypes: Constants.SUPPORTED_FLASHCARD_FULLSCREEN_TYPES
  },
  assignmentType: undefined,
  subject: undefined,
  skills: [],
  canListen: true,
  canSpeak: true
}

const useStore = createWithEqualityFn(() => initialState)

type NewLearningAssignment = {
  learningExecutionId: string
  assignmentType: LearningAssignmentType
  progress?: number
  skills?: Skill[]
  timeLimitInSeconds?: number
  subject: Subject
  defaultName?: string
  previousRouter?: string
  canSpeak?: boolean
}

export const lessonInputsStore = {
  setNewLearningAssignment: ({
    assignmentType,
    learningExecutionId,
    defaultName,
    previousRouter,
    skills,
    subject,
    timeLimitInSeconds,
    canSpeak
  }: NewLearningAssignment): void => {
    useStore.setState((store) => {
      const previousListening = store.canListen
      const previousSpeaking = canSpeak ?? store.canSpeak
      const nextFlashCardInput: Partial<NextFlashCardInput> = {
        interactionTypes: [
          ...(previousSpeaking ? [InteractionType.SPEAKING] : []),
          ...(previousListening ? [InteractionType.LISTENING] : [])
        ],
        learningExecutionId
      }

      return {
        assignmentType,
        skills,
        timeLimitInSeconds,
        nextFlashCardInput: { ...store.nextFlashCardInput, ...nextFlashCardInput },
        startTimestamp: undefined,
        canListen: previousListening,
        canSpeak: previousSpeaking,
        subject,
        defaultName,
        previousRouter
      }
    })
  },
  setInteractionTypes: (canSpeak: boolean, canListen: boolean): void => {
    const interactionTypes: NextFlashCardInput['interactionTypes'] = [
      ...(canSpeak ? [InteractionType.SPEAKING] : []),
      ...(canListen ? [InteractionType.LISTENING] : [])
    ]

    useStore.setState((store) => ({
      nextFlashCardInput: { ...store.nextFlashCardInput, interactionTypes },
      canListen,
      canSpeak
    }))
  },

  setStartLessonTimestamp: (): void => {
    useStore.setState(() => ({
      startTimestamp: Date.now()
    }))
  },
  getIsTest: () => {
    return useStore.getState().assignmentType === LearningAssignmentType.TEST
  },

  clearStore: (): void => {
    useStore.setState(
      (store) => ({
        nextFlashCardInput: {
          ...store.nextFlashCardInput,
          learningExecutionId: ''
        },
        canListen: true,
        canSpeak: true
      }),
      true
    )
  },
  useStore
}
